<template></template>

<script>
export default {
    data() {
        return {
            token:null,
            expires_in:null,
            data: null
        }
    },

    mounted() {
        this.token = {
            access_token: this.$route.query.token,
            expires_in: this.$route.query.expires_in,
            message: "Impersonate Realizado com sucesso",
            token_type:"bearer"
        }

        localStorage.setItem('token', JSON.stringify(this.token));

        this.$router.push('/home');
    },
}
</script>

<style></style>